<template>
  <div id="app" :class="isRunMobile ? 'mobile-layout': ''">
    <!-- <div class="center-this"> -->
    <div v-if="!isRunMobile & isGetData" class="dekstop-version">
      <img
        src="@/assets/img/caredokter.png"
        alt=""
       class="img-position"
      />
      <!--  class="position-absolute top-40" -->
      <div class="grid-container">
        <div class="grid-container-detail position-relative">
          <div class="position-relative">
            <div class="caredokter-bg-title"></div>
            <container-left-info />
          </div>
          
          <footer-customer />
        </div>
        <div class="grid-container-stack position-relative">
          <div class="row m-0" >
            <div class="col-12 pb-3 caredokter-pad-no">
              <img v-if="historySNAP.length > 0"
                src="@/assets/img/chevron-left.png"
                class="img-chevron-left"
                @click.prevent="setToBeforeRoute"
              />
              <label class="pl-4 right-container-label text-font-1">{{name}}</label>
            </div>
          </div>
          <router-view></router-view>
          <footer-component />
        </div>
      </div>

      <!-- </div> -->
    </div>
    <div v-else-if="isRunMobile && isGetData" class="mobile-version">
        <!-- MOBILE -->
        <!-- {{clientHeight}} -->
        <div class="d-none bg-backdrop" id="bg-backdrop"></div>
        <div class="caredokter-bg-title" :class="[$route.name, showIntroduction ? 'd-none': '']"  v-if="!$store.state.isChildren"></div>
        <router-view></router-view>
        <float-info-customer />
    </div>
    <div v-else>
       <not-found></not-found>
    </div>
  </div>
</template>
<script>
import ContainerLeftInfo from "../components/leftComponent/ContainerLeftInfo.vue";
import FooterCustomer from "@/components/leftComponent/FooterCustomer";
import SockJS from "sockjs-client";
import { JSEncrypt } from "jsencrypt";
import Stomp from "webstomp-client";
import { mapActions, mapMutations, mapState } from "vuex";
// import RightContainer from '../components/rightComponent/RightContainer.vue';
import FooterComponent from "../components/rightComponent/FooterComponent.vue";

import FloatInfoCustomer from '../components/mobile/FloatInfoCustomer.vue'

import NotFound from './../components/NotFound.vue'
export default {
  computed:{
     ...mapState({
        isGetData: (state) => state.isGetData,
        historySNAP: (state) => state.historySNAP,
        isRunMobile: state => state.isRunMobile,
        showIntroduction: (state) => state.showIntroduction,
        name: (state) => state.parentName
     })
  },
  components: {
    ContainerLeftInfo,
    FooterCustomer,
    // RightContainer,
    FooterComponent,
    
    FloatInfoCustomer,
   
    NotFound
  },
  data() {
    return {
      stompClient: "",
      clientHeight: ''
      // isMobile: false
    };
  },
  created() {
    document.title = "Snap Payment";
  },
  mounted() {
    const orderId = this.$route.params.orderId;
    this.clientHeight = document.documentElement.clientHeight + 'X ' + document.documentElement.clientWidth
    // if(document.documentElement.clientWidth < 1110) {
        // this.isMobile = true
        // this.getPaymentMobile(orderId)
        
    // }
    
    // console.log('masukkk',orderId)
    if (orderId) {
      // hit API
      this.getPaymentDetail(orderId);
      this.connect(orderId);
    } else {
      this.changeNotFound(false);
      // go to other page
    }
  },
  methods: {
    ...mapActions({
      getPaymentDetail: "GET_PAYMENT_DETAIL",
      setToBeforeRoute: "SET_TO_BEFORE_ROUTE",
      // getPaymentMobile: 'GET_LIST_PAYMENT_MOBILE'
    }),
    ...mapMutations({
      changeNotFound: "SET_IS_GET_DATA",
      changeDetail: "SET_IS_SHOW_DEFAULT",
    }),
    connect(orderId) {
      const encryptor = new JSEncrypt();
      encryptor.setPublicKey(process.env.VUE_APP_PUBLIC_KEY);
      const key = encryptor.encrypt(orderId);
      const urlParams = `${process.env.VUE_APP_BASE_URL}/websocket/payment/snap?key=${key}`;
      const socket = new SockJS(urlParams);
      this.stompClient = Stomp.over(socket);
      const that = this;
      this.stompClient.debug = () => {};
      this.stompClient.connect(
        {},
        () => {
          // connect
          that.stomClientSubs(orderId);
        },
        (error) => {
          console.log(error);
          that.stompClient.disconnect();
        }
      );
    },
    stomClientSubs(orderId) {
      const that = this;
      this.stompClient.subscribe(`/topic/payment/snap/${orderId}`, (tick) => {
        let msg = JSON.parse(tick.body);
        console.log(msg);
        that.getPaymentDetail(orderId);
      });
    },
   
  },
};
</script>
